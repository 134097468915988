import React from "react";
import { Link } from "gatsby";
import { getStaticImgProps } from "helpers/getImgProps";
import * as styles from "./goBack.module.scss";

const GoBack = ({ name, image }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.backToAll} d-flex justify-content-start align-items-center mv-15`}
      >
        <Link to="/careers/#vacantPos">
          <span className={`mr-15`}>
            <img {...getStaticImgProps(image)} />
          </span>
        </Link>
        <span> {name}</span>
      </div>
    </div>
  );
};

export default GoBack;
