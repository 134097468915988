import React from "react";
import * as styles from "./vacancyDetails.module.scss";

const VacancyDetails = ({
  country,
  dueDate,
  publishedDate,
  jobType,
  name,
  positionDetails,
}) => {
  const {
    introduction,
    introCriteria,
    jobResponsibilities,
    jobCriteria,
    requirements,
    requirementCriteria,
    additionalInfo,
    additionallCriteria,
  } = positionDetails;
  const introCriteriaList = introCriteria.map(({ text }, i) => {
    return (
      <p key={i} className={`${styles.text} mb-15`}>
        {text}
      </p>
    );
  });
  const jobCriteriaList = jobCriteria.map(({ text }, i) => {
    return (
      <p key={i} className={`${styles.text}`}>
        {text}
      </p>
    );
  });
  const requirementsCriteriaList = requirementCriteria.map(({ text }, i) => {
    return (
      <p key={i} className={`${styles.text}`}>
        {text}
      </p>
    );
  });
  return (
    <div className={styles.wrapper}>
      <p className={`${styles.positionName} mb-10`}>{name}</p>
      <p className={`${styles.jobType} mb-25`}>
        {jobType} ・ {country}
      </p>
      <div
        className={`${styles.date} mb-50 d-flex flex-column flex-md-row justify-content-start align-items-md-center`}
      >
        <p>
          <span>Published: </span>
          {publishedDate} <span className={styles.point}>・</span>
        </p>
        <p>
          <span>Due date to apply:</span>
          {dueDate}
        </p>
      </div>
      <div className={`${styles.careerDetails} mb-80`}>
        <div>
          <p className={styles.title}>{introduction}</p>
          {introCriteriaList}
        </div>
        <div>
          <p className={styles.title}>{jobResponsibilities}</p>
          {jobCriteriaList}
        </div>
        <div>
          <p className={styles.title}>{requirements}</p>
          {requirementsCriteriaList}
        </div>
        {additionallCriteria.length > 0 && (
          <div>
            <p className={styles.title}>{additionalInfo}</p>
            {additionallCriteria.map(({ text }, i) => {
              return (
                <p key={i} className={`${styles.text}`}>
                  {text}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default VacancyDetails;
