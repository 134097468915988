import React from "react";
import * as styles from "./sendEmail.module.scss";

const SendEmail = ({ email, sendEmail, interested }) => {
  return (
    <div className={styles.wrapper}>
      <p>{interested} </p>
      <p className={`${styles.send} mb-20`}>{sendEmail}</p>
      <p className={`${styles.email} mb-50`}>
        <a href="mailto: narer@preezma.com">{email}</a>
      </p>
    </div>
  );
};

export default SendEmail;
