export const getCareerDetailsPageProps = ({
  strapiCareerDetailsPage: {
    letsTalk,
    goBack,
    seo,
    email,
    interested,
    sendEmail,
  },
}) => {
  return {
    letsTalk,
    goBack,
    seo,
    email,
    interested,
    sendEmail,
  };
};
