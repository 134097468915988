import React from "react";
import { graphql } from "gatsby";
import VacancyDetails from "components/vacancyDetails";
import GoBack from "components/goBack";
import SendEmail from "components/sendEmail";
import Seo from "components/seo";
import LetsTalkComponent from "components/letsTalkComponent";
import { getCareerDetailsPageProps } from "helpers/getCareerDetailsPageProps";
import { getPositionDetailsProps } from "helpers/getPositionDetailsProps";
import Layout from "components/layout";

const VacancyPage = ({ data }) => {
  const { seo, letsTalk, goBack, email, sendEmail, interested } =
    getCareerDetailsPageProps(data);

  return (
    <Layout>
      <Seo {...seo} />
      <GoBack {...goBack} />
      <VacancyDetails {...getPositionDetailsProps(data)} />
      <SendEmail email={email} sendEmail={sendEmail} interested={interested} />
      <LetsTalkComponent {...letsTalk} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: String, $slug: String) {
    strapiVacantPosition(id: { eq: $id }, slug: { eq: $slug }) {
      id
      slug
      name
      jobType
      country
      dueDate
      publishedDate
      arrowRight {
        alternativeText
        localFile {
          url
        }
      }
      positionDetails {
        requirements
        jobResponsibilities
        introduction
        additionalInfo
        additionallCriteria {
          text
        }
        introCriteria {
          text
        }
        jobCriteria {
          text
        }
        requirementCriteria {
          text
        }
      }
    }
    strapiCareerDetailsPage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      email
      interested
      sendEmail
      goBack {
        name
        image {
          alternativeText
          localFile {
            url
          }
        }
      }
      letsTalk {
        title
        subtitle
        btn
        bgDark
        address
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        preezmaLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default VacancyPage;
