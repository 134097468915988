// extracted by mini-css-extract-plugin
export var careerDetails = "vacancyDetails-module--careerDetails--fbedb";
export var date = "vacancyDetails-module--date--2e527";
export var jobType = "vacancyDetails-module--jobType--f095d";
export var ma0 = "vacancyDetails-module--ma-0--0546b";
export var ma10 = "vacancyDetails-module--ma-10--ab8c0";
export var ma100 = "vacancyDetails-module--ma-100--74652";
export var ma105 = "vacancyDetails-module--ma-105--37746";
export var ma110 = "vacancyDetails-module--ma-110--0e513";
export var ma115 = "vacancyDetails-module--ma-115--9c223";
export var ma12 = "vacancyDetails-module--ma-12--95111";
export var ma120 = "vacancyDetails-module--ma-120--1f0c9";
export var ma130 = "vacancyDetails-module--ma-130--cf62f";
export var ma140 = "vacancyDetails-module--ma-140--90cf6";
export var ma15 = "vacancyDetails-module--ma-15--fe33e";
export var ma150 = "vacancyDetails-module--ma-150--61c56";
export var ma16 = "vacancyDetails-module--ma-16--29fa6";
export var ma18 = "vacancyDetails-module--ma-18--ad2ef";
export var ma185 = "vacancyDetails-module--ma-185--156e9";
export var ma20 = "vacancyDetails-module--ma-20--c1317";
export var ma25 = "vacancyDetails-module--ma-25--d0914";
export var ma30 = "vacancyDetails-module--ma-30--1660a";
export var ma35 = "vacancyDetails-module--ma-35--bb442";
export var ma40 = "vacancyDetails-module--ma-40--ee749";
export var ma45 = "vacancyDetails-module--ma-45--d7674";
export var ma48 = "vacancyDetails-module--ma-48--0d8e4";
export var ma5 = "vacancyDetails-module--ma-5--2d910";
export var ma50 = "vacancyDetails-module--ma-50--2dc13";
export var ma55 = "vacancyDetails-module--ma-55--0cc3d";
export var ma60 = "vacancyDetails-module--ma-60--e97a1";
export var ma70 = "vacancyDetails-module--ma-70--ad653";
export var ma80 = "vacancyDetails-module--ma-80--c14bc";
export var ma85 = "vacancyDetails-module--ma-85--1acc6";
export var ma90 = "vacancyDetails-module--ma-90--447cb";
export var ma95 = "vacancyDetails-module--ma-95--b9185";
export var mb0 = "vacancyDetails-module--mb-0--50342";
export var mb10 = "vacancyDetails-module--mb-10--cde71";
export var mb100 = "vacancyDetails-module--mb-100--e710e";
export var mb105 = "vacancyDetails-module--mb-105--17091";
export var mb110 = "vacancyDetails-module--mb-110--d4b45";
export var mb115 = "vacancyDetails-module--mb-115--2ae1f";
export var mb12 = "vacancyDetails-module--mb-12--f1c3b";
export var mb120 = "vacancyDetails-module--mb-120--10b5a";
export var mb130 = "vacancyDetails-module--mb-130--27b15";
export var mb140 = "vacancyDetails-module--mb-140--72ad7";
export var mb15 = "vacancyDetails-module--mb-15--54a1a";
export var mb150 = "vacancyDetails-module--mb-150--03960";
export var mb16 = "vacancyDetails-module--mb-16--4ad5c";
export var mb18 = "vacancyDetails-module--mb-18--02cd6";
export var mb185 = "vacancyDetails-module--mb-185--280bd";
export var mb20 = "vacancyDetails-module--mb-20--abd06";
export var mb25 = "vacancyDetails-module--mb-25--3d86e";
export var mb30 = "vacancyDetails-module--mb-30--4b20b";
export var mb35 = "vacancyDetails-module--mb-35--9e8ec";
export var mb40 = "vacancyDetails-module--mb-40--b58ba";
export var mb45 = "vacancyDetails-module--mb-45--8e1c1";
export var mb48 = "vacancyDetails-module--mb-48--43b00";
export var mb5 = "vacancyDetails-module--mb-5--b5da0";
export var mb50 = "vacancyDetails-module--mb-50--47313";
export var mb55 = "vacancyDetails-module--mb-55--16211";
export var mb60 = "vacancyDetails-module--mb-60--eae2a";
export var mb70 = "vacancyDetails-module--mb-70--deea5";
export var mb80 = "vacancyDetails-module--mb-80--d577d";
export var mb85 = "vacancyDetails-module--mb-85--5eb23";
export var mb90 = "vacancyDetails-module--mb-90--b16ed";
export var mb95 = "vacancyDetails-module--mb-95--270a8";
export var mh0 = "vacancyDetails-module--mh-0--67640";
export var mh10 = "vacancyDetails-module--mh-10--551e1";
export var mh100 = "vacancyDetails-module--mh-100--f4dd5";
export var mh105 = "vacancyDetails-module--mh-105--0df1b";
export var mh110 = "vacancyDetails-module--mh-110--c170b";
export var mh115 = "vacancyDetails-module--mh-115--f8eef";
export var mh12 = "vacancyDetails-module--mh-12--5044a";
export var mh120 = "vacancyDetails-module--mh-120--c6196";
export var mh130 = "vacancyDetails-module--mh-130--9e652";
export var mh140 = "vacancyDetails-module--mh-140--16a40";
export var mh15 = "vacancyDetails-module--mh-15--d2ae1";
export var mh150 = "vacancyDetails-module--mh-150--7e9fe";
export var mh16 = "vacancyDetails-module--mh-16--5e7fb";
export var mh18 = "vacancyDetails-module--mh-18--9a7dd";
export var mh185 = "vacancyDetails-module--mh-185--c0436";
export var mh20 = "vacancyDetails-module--mh-20--d183d";
export var mh25 = "vacancyDetails-module--mh-25--67c38";
export var mh30 = "vacancyDetails-module--mh-30--00059";
export var mh35 = "vacancyDetails-module--mh-35--9dc47";
export var mh40 = "vacancyDetails-module--mh-40--31998";
export var mh45 = "vacancyDetails-module--mh-45--44786";
export var mh48 = "vacancyDetails-module--mh-48--a6a50";
export var mh5 = "vacancyDetails-module--mh-5--240e7";
export var mh50 = "vacancyDetails-module--mh-50--ad538";
export var mh55 = "vacancyDetails-module--mh-55--70450";
export var mh60 = "vacancyDetails-module--mh-60--4d2d5";
export var mh70 = "vacancyDetails-module--mh-70--880ec";
export var mh80 = "vacancyDetails-module--mh-80--b5cdc";
export var mh85 = "vacancyDetails-module--mh-85--d82c9";
export var mh90 = "vacancyDetails-module--mh-90--45a23";
export var mh95 = "vacancyDetails-module--mh-95--8f63e";
export var ml0 = "vacancyDetails-module--ml-0--826ef";
export var ml10 = "vacancyDetails-module--ml-10--9cc15";
export var ml100 = "vacancyDetails-module--ml-100--f8d25";
export var ml105 = "vacancyDetails-module--ml-105--5bf4c";
export var ml110 = "vacancyDetails-module--ml-110--7edde";
export var ml115 = "vacancyDetails-module--ml-115--1ef13";
export var ml12 = "vacancyDetails-module--ml-12--32c10";
export var ml120 = "vacancyDetails-module--ml-120--b21e5";
export var ml130 = "vacancyDetails-module--ml-130--fb5d2";
export var ml140 = "vacancyDetails-module--ml-140--3f3c4";
export var ml15 = "vacancyDetails-module--ml-15--6df09";
export var ml150 = "vacancyDetails-module--ml-150--c4173";
export var ml16 = "vacancyDetails-module--ml-16--996e3";
export var ml18 = "vacancyDetails-module--ml-18--d85a4";
export var ml185 = "vacancyDetails-module--ml-185--d8914";
export var ml20 = "vacancyDetails-module--ml-20--f32d3";
export var ml25 = "vacancyDetails-module--ml-25--f6819";
export var ml30 = "vacancyDetails-module--ml-30--cadb8";
export var ml35 = "vacancyDetails-module--ml-35--ea295";
export var ml40 = "vacancyDetails-module--ml-40--5c2dc";
export var ml45 = "vacancyDetails-module--ml-45--5a962";
export var ml48 = "vacancyDetails-module--ml-48--c6b97";
export var ml5 = "vacancyDetails-module--ml-5--7369e";
export var ml50 = "vacancyDetails-module--ml-50--83609";
export var ml55 = "vacancyDetails-module--ml-55--e33d1";
export var ml60 = "vacancyDetails-module--ml-60--e7930";
export var ml70 = "vacancyDetails-module--ml-70--22808";
export var ml80 = "vacancyDetails-module--ml-80--cfea8";
export var ml85 = "vacancyDetails-module--ml-85--f9469";
export var ml90 = "vacancyDetails-module--ml-90--ac021";
export var ml95 = "vacancyDetails-module--ml-95--beb40";
export var mr0 = "vacancyDetails-module--mr-0--b1d6c";
export var mr10 = "vacancyDetails-module--mr-10--0908e";
export var mr100 = "vacancyDetails-module--mr-100--c54ab";
export var mr105 = "vacancyDetails-module--mr-105--a103c";
export var mr110 = "vacancyDetails-module--mr-110--6e6ea";
export var mr115 = "vacancyDetails-module--mr-115--7d8b9";
export var mr12 = "vacancyDetails-module--mr-12--bbf78";
export var mr120 = "vacancyDetails-module--mr-120--4fe0a";
export var mr130 = "vacancyDetails-module--mr-130--3906f";
export var mr140 = "vacancyDetails-module--mr-140--9ba25";
export var mr15 = "vacancyDetails-module--mr-15--2ffd0";
export var mr150 = "vacancyDetails-module--mr-150--0bdb9";
export var mr16 = "vacancyDetails-module--mr-16--ade1b";
export var mr18 = "vacancyDetails-module--mr-18--bd0c3";
export var mr185 = "vacancyDetails-module--mr-185--90d14";
export var mr20 = "vacancyDetails-module--mr-20--5dad3";
export var mr25 = "vacancyDetails-module--mr-25--5fb8a";
export var mr30 = "vacancyDetails-module--mr-30--03b07";
export var mr35 = "vacancyDetails-module--mr-35--055c2";
export var mr40 = "vacancyDetails-module--mr-40--41d1e";
export var mr45 = "vacancyDetails-module--mr-45--22dcf";
export var mr48 = "vacancyDetails-module--mr-48--9d504";
export var mr5 = "vacancyDetails-module--mr-5--2dcad";
export var mr50 = "vacancyDetails-module--mr-50--ba3f9";
export var mr55 = "vacancyDetails-module--mr-55--1484f";
export var mr60 = "vacancyDetails-module--mr-60--4ab25";
export var mr70 = "vacancyDetails-module--mr-70--9fac4";
export var mr80 = "vacancyDetails-module--mr-80--75111";
export var mr85 = "vacancyDetails-module--mr-85--3b346";
export var mr90 = "vacancyDetails-module--mr-90--a683e";
export var mr95 = "vacancyDetails-module--mr-95--6f967";
export var mt0 = "vacancyDetails-module--mt-0--473d5";
export var mt10 = "vacancyDetails-module--mt-10--157ac";
export var mt100 = "vacancyDetails-module--mt-100--c4433";
export var mt105 = "vacancyDetails-module--mt-105--9e72a";
export var mt110 = "vacancyDetails-module--mt-110--226e8";
export var mt115 = "vacancyDetails-module--mt-115--27d95";
export var mt12 = "vacancyDetails-module--mt-12--4c282";
export var mt120 = "vacancyDetails-module--mt-120--a1c94";
export var mt130 = "vacancyDetails-module--mt-130--010b6";
export var mt140 = "vacancyDetails-module--mt-140--53d95";
export var mt15 = "vacancyDetails-module--mt-15--d17ba";
export var mt150 = "vacancyDetails-module--mt-150--73e34";
export var mt16 = "vacancyDetails-module--mt-16--adaf4";
export var mt18 = "vacancyDetails-module--mt-18--b20c9";
export var mt185 = "vacancyDetails-module--mt-185--db271";
export var mt20 = "vacancyDetails-module--mt-20--3775d";
export var mt25 = "vacancyDetails-module--mt-25--aac1a";
export var mt30 = "vacancyDetails-module--mt-30--0a777";
export var mt35 = "vacancyDetails-module--mt-35--64129";
export var mt40 = "vacancyDetails-module--mt-40--51168";
export var mt45 = "vacancyDetails-module--mt-45--7913f";
export var mt48 = "vacancyDetails-module--mt-48--c6f31";
export var mt5 = "vacancyDetails-module--mt-5--086d0";
export var mt50 = "vacancyDetails-module--mt-50--4a4b5";
export var mt55 = "vacancyDetails-module--mt-55--bdd36";
export var mt60 = "vacancyDetails-module--mt-60--e0b6e";
export var mt70 = "vacancyDetails-module--mt-70--bb928";
export var mt80 = "vacancyDetails-module--mt-80--de410";
export var mt85 = "vacancyDetails-module--mt-85--2dac9";
export var mt90 = "vacancyDetails-module--mt-90--d66f1";
export var mt95 = "vacancyDetails-module--mt-95--81d75";
export var mv0 = "vacancyDetails-module--mv-0--4febc";
export var mv10 = "vacancyDetails-module--mv-10--b0a52";
export var mv100 = "vacancyDetails-module--mv-100--a7dc0";
export var mv105 = "vacancyDetails-module--mv-105--5bca2";
export var mv110 = "vacancyDetails-module--mv-110--ab70d";
export var mv115 = "vacancyDetails-module--mv-115--d958b";
export var mv12 = "vacancyDetails-module--mv-12--8c1be";
export var mv120 = "vacancyDetails-module--mv-120--5b4a1";
export var mv130 = "vacancyDetails-module--mv-130--6b864";
export var mv140 = "vacancyDetails-module--mv-140--b62a3";
export var mv15 = "vacancyDetails-module--mv-15--dfb33";
export var mv150 = "vacancyDetails-module--mv-150--faa0e";
export var mv16 = "vacancyDetails-module--mv-16--cea81";
export var mv18 = "vacancyDetails-module--mv-18--61791";
export var mv185 = "vacancyDetails-module--mv-185--d172b";
export var mv20 = "vacancyDetails-module--mv-20--c2a73";
export var mv25 = "vacancyDetails-module--mv-25--f05c6";
export var mv30 = "vacancyDetails-module--mv-30--f2bda";
export var mv35 = "vacancyDetails-module--mv-35--501e2";
export var mv40 = "vacancyDetails-module--mv-40--e6922";
export var mv45 = "vacancyDetails-module--mv-45--54c03";
export var mv48 = "vacancyDetails-module--mv-48--cc3aa";
export var mv5 = "vacancyDetails-module--mv-5--05d57";
export var mv50 = "vacancyDetails-module--mv-50--5c90d";
export var mv55 = "vacancyDetails-module--mv-55--f290e";
export var mv60 = "vacancyDetails-module--mv-60--6cf6f";
export var mv70 = "vacancyDetails-module--mv-70--df834";
export var mv80 = "vacancyDetails-module--mv-80--f4f6e";
export var mv85 = "vacancyDetails-module--mv-85--5d412";
export var mv90 = "vacancyDetails-module--mv-90--dcad1";
export var mv95 = "vacancyDetails-module--mv-95--a577d";
export var pa0 = "vacancyDetails-module--pa-0--74876";
export var pa10 = "vacancyDetails-module--pa-10--fc943";
export var pa100 = "vacancyDetails-module--pa-100--e3069";
export var pa105 = "vacancyDetails-module--pa-105--66926";
export var pa110 = "vacancyDetails-module--pa-110--aa881";
export var pa115 = "vacancyDetails-module--pa-115--93e3a";
export var pa12 = "vacancyDetails-module--pa-12--77a74";
export var pa120 = "vacancyDetails-module--pa-120--f4c24";
export var pa130 = "vacancyDetails-module--pa-130--2cf2c";
export var pa140 = "vacancyDetails-module--pa-140--7a60f";
export var pa15 = "vacancyDetails-module--pa-15--00390";
export var pa150 = "vacancyDetails-module--pa-150--eff17";
export var pa16 = "vacancyDetails-module--pa-16--5e876";
export var pa18 = "vacancyDetails-module--pa-18--c46a1";
export var pa185 = "vacancyDetails-module--pa-185--9a54b";
export var pa20 = "vacancyDetails-module--pa-20--69801";
export var pa25 = "vacancyDetails-module--pa-25--2bc2e";
export var pa30 = "vacancyDetails-module--pa-30--fecd3";
export var pa35 = "vacancyDetails-module--pa-35--129f6";
export var pa40 = "vacancyDetails-module--pa-40--ed9df";
export var pa45 = "vacancyDetails-module--pa-45--818bb";
export var pa48 = "vacancyDetails-module--pa-48--4a5f0";
export var pa5 = "vacancyDetails-module--pa-5--3525e";
export var pa50 = "vacancyDetails-module--pa-50--3cb03";
export var pa55 = "vacancyDetails-module--pa-55--9d68f";
export var pa60 = "vacancyDetails-module--pa-60--da515";
export var pa70 = "vacancyDetails-module--pa-70--94093";
export var pa80 = "vacancyDetails-module--pa-80--c4161";
export var pa85 = "vacancyDetails-module--pa-85--a0b07";
export var pa90 = "vacancyDetails-module--pa-90--14a0a";
export var pa95 = "vacancyDetails-module--pa-95--eb633";
export var pb0 = "vacancyDetails-module--pb-0--8dc36";
export var pb10 = "vacancyDetails-module--pb-10--942b9";
export var pb100 = "vacancyDetails-module--pb-100--78acc";
export var pb105 = "vacancyDetails-module--pb-105--02dae";
export var pb110 = "vacancyDetails-module--pb-110--a2fc4";
export var pb115 = "vacancyDetails-module--pb-115--c1559";
export var pb12 = "vacancyDetails-module--pb-12--a1a54";
export var pb120 = "vacancyDetails-module--pb-120--2eaee";
export var pb130 = "vacancyDetails-module--pb-130--65948";
export var pb140 = "vacancyDetails-module--pb-140--6ab0c";
export var pb15 = "vacancyDetails-module--pb-15--82f38";
export var pb150 = "vacancyDetails-module--pb-150--f9ac1";
export var pb16 = "vacancyDetails-module--pb-16--98477";
export var pb18 = "vacancyDetails-module--pb-18--f6187";
export var pb185 = "vacancyDetails-module--pb-185--37778";
export var pb20 = "vacancyDetails-module--pb-20--34566";
export var pb25 = "vacancyDetails-module--pb-25--86bfe";
export var pb30 = "vacancyDetails-module--pb-30--a2be7";
export var pb35 = "vacancyDetails-module--pb-35--d7a98";
export var pb40 = "vacancyDetails-module--pb-40--30c1c";
export var pb45 = "vacancyDetails-module--pb-45--48236";
export var pb48 = "vacancyDetails-module--pb-48--eed35";
export var pb5 = "vacancyDetails-module--pb-5--6fe09";
export var pb50 = "vacancyDetails-module--pb-50--524ad";
export var pb55 = "vacancyDetails-module--pb-55--09c68";
export var pb60 = "vacancyDetails-module--pb-60--b66b8";
export var pb70 = "vacancyDetails-module--pb-70--b44f8";
export var pb80 = "vacancyDetails-module--pb-80--14449";
export var pb85 = "vacancyDetails-module--pb-85--73ef7";
export var pb90 = "vacancyDetails-module--pb-90--54f50";
export var pb95 = "vacancyDetails-module--pb-95--60ad2";
export var ph0 = "vacancyDetails-module--ph-0--ef29f";
export var ph10 = "vacancyDetails-module--ph-10--85828";
export var ph100 = "vacancyDetails-module--ph-100--66475";
export var ph105 = "vacancyDetails-module--ph-105--649d9";
export var ph110 = "vacancyDetails-module--ph-110--ba097";
export var ph115 = "vacancyDetails-module--ph-115--e37d9";
export var ph12 = "vacancyDetails-module--ph-12--7812a";
export var ph120 = "vacancyDetails-module--ph-120--3cca4";
export var ph130 = "vacancyDetails-module--ph-130--bb296";
export var ph140 = "vacancyDetails-module--ph-140--34602";
export var ph15 = "vacancyDetails-module--ph-15--3c7f8";
export var ph150 = "vacancyDetails-module--ph-150--22a96";
export var ph16 = "vacancyDetails-module--ph-16--93459";
export var ph18 = "vacancyDetails-module--ph-18--46808";
export var ph185 = "vacancyDetails-module--ph-185--eac19";
export var ph20 = "vacancyDetails-module--ph-20--8ed81";
export var ph25 = "vacancyDetails-module--ph-25--c1d19";
export var ph30 = "vacancyDetails-module--ph-30--c28af";
export var ph35 = "vacancyDetails-module--ph-35--35e1e";
export var ph40 = "vacancyDetails-module--ph-40--3626c";
export var ph45 = "vacancyDetails-module--ph-45--5fb30";
export var ph48 = "vacancyDetails-module--ph-48--f29f0";
export var ph5 = "vacancyDetails-module--ph-5--c4bf9";
export var ph50 = "vacancyDetails-module--ph-50--06aa1";
export var ph55 = "vacancyDetails-module--ph-55--4d860";
export var ph60 = "vacancyDetails-module--ph-60--bc021";
export var ph70 = "vacancyDetails-module--ph-70--b870c";
export var ph80 = "vacancyDetails-module--ph-80--1473d";
export var ph85 = "vacancyDetails-module--ph-85--fbd1a";
export var ph90 = "vacancyDetails-module--ph-90--471b2";
export var ph95 = "vacancyDetails-module--ph-95--169dd";
export var pl0 = "vacancyDetails-module--pl-0--6a8c0";
export var pl10 = "vacancyDetails-module--pl-10--526c8";
export var pl100 = "vacancyDetails-module--pl-100--55694";
export var pl105 = "vacancyDetails-module--pl-105--1619d";
export var pl110 = "vacancyDetails-module--pl-110--f4c07";
export var pl115 = "vacancyDetails-module--pl-115--0dced";
export var pl12 = "vacancyDetails-module--pl-12--7fef6";
export var pl120 = "vacancyDetails-module--pl-120--7caa3";
export var pl130 = "vacancyDetails-module--pl-130--79b31";
export var pl140 = "vacancyDetails-module--pl-140--9e8b4";
export var pl15 = "vacancyDetails-module--pl-15--34a85";
export var pl150 = "vacancyDetails-module--pl-150--5c8c1";
export var pl16 = "vacancyDetails-module--pl-16--66389";
export var pl18 = "vacancyDetails-module--pl-18--b6e80";
export var pl185 = "vacancyDetails-module--pl-185--7248d";
export var pl20 = "vacancyDetails-module--pl-20--71138";
export var pl25 = "vacancyDetails-module--pl-25--436d8";
export var pl30 = "vacancyDetails-module--pl-30--cfd3d";
export var pl35 = "vacancyDetails-module--pl-35--8a3dc";
export var pl40 = "vacancyDetails-module--pl-40--d401f";
export var pl45 = "vacancyDetails-module--pl-45--5f605";
export var pl48 = "vacancyDetails-module--pl-48--eb998";
export var pl5 = "vacancyDetails-module--pl-5--09397";
export var pl50 = "vacancyDetails-module--pl-50--16436";
export var pl55 = "vacancyDetails-module--pl-55--e63a1";
export var pl60 = "vacancyDetails-module--pl-60--d0304";
export var pl70 = "vacancyDetails-module--pl-70--06b93";
export var pl80 = "vacancyDetails-module--pl-80--c833a";
export var pl85 = "vacancyDetails-module--pl-85--777f9";
export var pl90 = "vacancyDetails-module--pl-90--3b789";
export var pl95 = "vacancyDetails-module--pl-95--c6d33";
export var point = "vacancyDetails-module--point--338a2";
export var positionName = "vacancyDetails-module--positionName--1ea33";
export var pr0 = "vacancyDetails-module--pr-0--bbf00";
export var pr10 = "vacancyDetails-module--pr-10--3c7f2";
export var pr100 = "vacancyDetails-module--pr-100--5f771";
export var pr105 = "vacancyDetails-module--pr-105--c388e";
export var pr110 = "vacancyDetails-module--pr-110--a176d";
export var pr115 = "vacancyDetails-module--pr-115--6e179";
export var pr12 = "vacancyDetails-module--pr-12--4eb9d";
export var pr120 = "vacancyDetails-module--pr-120--8c35d";
export var pr130 = "vacancyDetails-module--pr-130--f5efa";
export var pr140 = "vacancyDetails-module--pr-140--d1b7d";
export var pr15 = "vacancyDetails-module--pr-15--b87d6";
export var pr150 = "vacancyDetails-module--pr-150--8bacf";
export var pr16 = "vacancyDetails-module--pr-16--680d4";
export var pr18 = "vacancyDetails-module--pr-18--d037a";
export var pr185 = "vacancyDetails-module--pr-185--b4811";
export var pr20 = "vacancyDetails-module--pr-20--8f1f2";
export var pr25 = "vacancyDetails-module--pr-25--285d2";
export var pr30 = "vacancyDetails-module--pr-30--e0b5f";
export var pr35 = "vacancyDetails-module--pr-35--070a7";
export var pr40 = "vacancyDetails-module--pr-40--b6786";
export var pr45 = "vacancyDetails-module--pr-45--dc4b9";
export var pr48 = "vacancyDetails-module--pr-48--e8ba0";
export var pr5 = "vacancyDetails-module--pr-5--0e30a";
export var pr50 = "vacancyDetails-module--pr-50--107fe";
export var pr55 = "vacancyDetails-module--pr-55--d2f64";
export var pr60 = "vacancyDetails-module--pr-60--80eed";
export var pr70 = "vacancyDetails-module--pr-70--6081a";
export var pr80 = "vacancyDetails-module--pr-80--c25bd";
export var pr85 = "vacancyDetails-module--pr-85--efbd3";
export var pr90 = "vacancyDetails-module--pr-90--ecd23";
export var pr95 = "vacancyDetails-module--pr-95--53121";
export var pt0 = "vacancyDetails-module--pt-0--82ef0";
export var pt10 = "vacancyDetails-module--pt-10--9f202";
export var pt100 = "vacancyDetails-module--pt-100--63681";
export var pt105 = "vacancyDetails-module--pt-105--98478";
export var pt110 = "vacancyDetails-module--pt-110--b3dcd";
export var pt115 = "vacancyDetails-module--pt-115--b057e";
export var pt12 = "vacancyDetails-module--pt-12--cf511";
export var pt120 = "vacancyDetails-module--pt-120--c0420";
export var pt130 = "vacancyDetails-module--pt-130--307f8";
export var pt140 = "vacancyDetails-module--pt-140--7006a";
export var pt15 = "vacancyDetails-module--pt-15--9a489";
export var pt150 = "vacancyDetails-module--pt-150--d227f";
export var pt16 = "vacancyDetails-module--pt-16--6bb11";
export var pt18 = "vacancyDetails-module--pt-18--cb15f";
export var pt185 = "vacancyDetails-module--pt-185--3e419";
export var pt20 = "vacancyDetails-module--pt-20--1e09d";
export var pt25 = "vacancyDetails-module--pt-25--50638";
export var pt30 = "vacancyDetails-module--pt-30--d926a";
export var pt35 = "vacancyDetails-module--pt-35--5e422";
export var pt40 = "vacancyDetails-module--pt-40--051f1";
export var pt45 = "vacancyDetails-module--pt-45--a0d24";
export var pt48 = "vacancyDetails-module--pt-48--6931b";
export var pt5 = "vacancyDetails-module--pt-5--63848";
export var pt50 = "vacancyDetails-module--pt-50--44c55";
export var pt55 = "vacancyDetails-module--pt-55--90962";
export var pt60 = "vacancyDetails-module--pt-60--0ac20";
export var pt70 = "vacancyDetails-module--pt-70--4b10e";
export var pt80 = "vacancyDetails-module--pt-80--99c86";
export var pt85 = "vacancyDetails-module--pt-85--778cd";
export var pt90 = "vacancyDetails-module--pt-90--90586";
export var pt95 = "vacancyDetails-module--pt-95--ed686";
export var pv0 = "vacancyDetails-module--pv-0--e49f5";
export var pv10 = "vacancyDetails-module--pv-10--16396";
export var pv100 = "vacancyDetails-module--pv-100--bce92";
export var pv105 = "vacancyDetails-module--pv-105--91e58";
export var pv110 = "vacancyDetails-module--pv-110--f0ae5";
export var pv115 = "vacancyDetails-module--pv-115--4138e";
export var pv12 = "vacancyDetails-module--pv-12--4cb8f";
export var pv120 = "vacancyDetails-module--pv-120--df5c1";
export var pv130 = "vacancyDetails-module--pv-130--a1613";
export var pv140 = "vacancyDetails-module--pv-140--05473";
export var pv15 = "vacancyDetails-module--pv-15--d3bdd";
export var pv150 = "vacancyDetails-module--pv-150--739a2";
export var pv16 = "vacancyDetails-module--pv-16--7ec40";
export var pv18 = "vacancyDetails-module--pv-18--4a7a5";
export var pv185 = "vacancyDetails-module--pv-185--ba772";
export var pv20 = "vacancyDetails-module--pv-20--3e805";
export var pv25 = "vacancyDetails-module--pv-25--96cf2";
export var pv30 = "vacancyDetails-module--pv-30--dfd27";
export var pv35 = "vacancyDetails-module--pv-35--1c56e";
export var pv40 = "vacancyDetails-module--pv-40--b76d3";
export var pv45 = "vacancyDetails-module--pv-45--c1aec";
export var pv48 = "vacancyDetails-module--pv-48--71d3c";
export var pv5 = "vacancyDetails-module--pv-5--70881";
export var pv50 = "vacancyDetails-module--pv-50--a6da1";
export var pv55 = "vacancyDetails-module--pv-55--38395";
export var pv60 = "vacancyDetails-module--pv-60--5ece0";
export var pv70 = "vacancyDetails-module--pv-70--e8f6e";
export var pv80 = "vacancyDetails-module--pv-80--628cf";
export var pv85 = "vacancyDetails-module--pv-85--4ac84";
export var pv90 = "vacancyDetails-module--pv-90--831cf";
export var pv95 = "vacancyDetails-module--pv-95--bec13";
export var text = "vacancyDetails-module--text--d3980";
export var title = "vacancyDetails-module--title--1a09d";
export var wrapper = "vacancyDetails-module--wrapper--ac81f";