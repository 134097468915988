export const getPositionDetailsProps = ({
  strapiVacantPosition: {
    id,
    slug,
    country,
    name,
    jobType,
    dueDate,
    publishedDate,
    arrowRight,
    positionDetails,
  },
}) => {
  return {
    id,
    slug,
    country,
    name,
    jobType,
    dueDate,
    publishedDate,
    arrowRight,
    positionDetails,
  };
};
